<template>
  <div
    class="product-card__del-btn"
    @click="handleClick"
  >
    <sui_icon_delete_14px_1
      size="20px"
      color="#000000"
    />
  </div>
</template>


<script setup>
import { sui_icon_delete_14px_1 } from '@shein-aidc/icon-vue3'
import { defineEmits } from 'vue'
const emit = defineEmits(['deleteItem'])
const handleClick = () => {
  emit('deleteItem')
}
</script>

<script>
export default {
  name: 'ProductCardDelBtn',
}
</script>

<style lang='less'>
.product-card__del-btn {
  cursor: pointer;
}
</style>
