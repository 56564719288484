<template>
  <div 
    class="buy-box__container"
    @click.stop="onClick"
  >
    <div 
      ref="refBuyBox" 
      class="buy-box__label"
    >
      <!-- count -->
      <span :class="countData.className">
        <span>
          {{ countData.all }}
          <span class="buy-box__num">{{ count }}</span>
          {{ countData.otherSellers }}
        </span>
      </span>

      <!-- price -->
      <span 
        v-if="price"
        :class="priceData.className"
      >
        <span>
          {{ priceData.otherSellers }}&nbsp;{{ priceData.from }}&nbsp;
          <span class="buy-box__num">{{ price }}</span>
        </span>
      </span>
    </div>

    <div
      class="buy-box__icon"
      :style="{ transform: cssRight ? 'rotateY(180deg)' : ''}"
    >
      <img
        src="https://img.ltwebstatic.com/images3_ccc/2024/07/30/7b/1722321036cc8136785ac4d08f94e393947ee8e161.png"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductCardBuyBoxEntrance',
}
</script>

<script setup>
import { ref, onMounted, watch, nextTick } from 'vue'
// eslint-disable-next-line no-unused-vars
import { expose as vExpose, tap as vTap } from 'public/src/pages/common/analysis/directive.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'

const emits = defineEmits(['openBuyBoxDrawer'])
const props = defineProps({
  count: { type: Number, default: 0 },
  price: { type: String, default: '' },
  goodsId: { type: String, default: '' },
  cssRight: { type: Boolean, default: false },
  language: { type: Object, default: () => ({}) },
})

const analysisExpose = () => {
  const { price, goodsId } = props
  daEventCenter.triggerNotice({
    daId: '2-3-40',
    extraData: {
      goods_id: goodsId,
      expose_times: price ? 2 : 1,
      buy_box_style: price ? 1 : 2
    }
  })
}
const analysisClick = () => {
  const { price, goodsId } = props
  daEventCenter.triggerNotice({
    daId: '2-3-41',
    extraData: {
      goods_id: goodsId,
      expose_times: price ? 2 : 1,
      buy_box_style: price ? 1 : 2
    }
  })
}


const countData = ref({})
const setCountData = () => {
  const { count = '', price, language } = props
  const languageText = language?.SHEIN_KEY_PC_30237 || ''
  const split = languageText.split('{0}')
  const all = split?.[0]?.trim() || ''
  const otherSellers = split?.[1]?.trim() || ''
  const className = price ? 'buy-box__label-container to-fade to-fade__out-start' : 'buy-box__label-container'
  countData.value = { all, otherSellers, count, className }
}

const priceData = ref({ className: 'buy-box__label-container' })
const setPriceData = () => {
  const { price = '', language } = props
  if (!price) return
  // 测试文案长度用
  // let otherSellers = language?.SHEIN_KEY_PC_30263 || ''
  // if (props.goodsId == 13726843) {
  //   otherSellers = new Array(3).fill(otherSellers).join(' ')
  // } else if (props.goodsId == 18055660) {
  //   otherSellers = new Array(6).fill(otherSellers).join(' ')
  // }
  const otherSellers = language?.SHEIN_KEY_PC_30263 || ''
  const from = language?.SHEIN_KEY_PC_30871 || ''
  const className = price ? 'buy-box__label-container to-fade to-fade__in-start' : 'buy-box__label-container'
  priceData.value = { otherSellers, from, price, className }
}


const num = ref(0)
const refBuyBox = ref()
const computedTextWidth = async () => {
  await nextTick()
  const dom = refBuyBox.value
  const child = dom?.children[1]?.querySelector('.buy-box__num')
  if (typeof window === 'undefined' || !child) return
  const rect1 = dom.getBoundingClientRect()
  const rect2 = child.getBoundingClientRect()
  const diff1 = rect1.bottom - rect2.bottom
  const diff2 = rect1.right - rect2.right
  if (num.value >= 20 || (diff1 > -2 && diff2 > -2)) {
    switchCountToPrice()
    return
  }

  num.value += 1
  const { otherSellers } = priceData.value
  priceData.value = {
    ...priceData.value,
    otherSellers: otherSellers.substring(0, otherSellers.length - 6) + '...'
  }

  requestAnimationFrame(() => {
    computedTextWidth()
  })
}
const switchCountToPrice = async () => {
  if (!props.price) return

  await nextTick()
  const dom = refBuyBox.value
  const domCount = dom.children[0]
  const domPrice = dom.children[1]
  domCount?.classList?.add('to-fade__out-end')
  domPrice?.classList?.add('to-fade__in-end')
}
watch(() => props.price, async (val) => {
  analysisExpose()
  setCountData()
  setPriceData()
  if (!val) return
  computedTextWidth()
})

watch(() => props.count, async (val) => {
  setCountData()
  analysisExpose()
}, { immediate: true })

onMounted(() => {
  if (!props.price) return
  setPriceData()
  computedTextWidth()
})

const onClick = () => {
  analysisClick()
  emits('openBuyBoxDrawer')
}
</script>

<style scoped lang="less">
@buyBoxHeight: 33px;


.buy-box {
  &__container {
    width: 100%;
    display: flex;
    height: @buyBoxHeight;
    border: 0.5px solid transparent;
    position: relative;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    margin-top: 6px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(90deg, #FFF6E6 0%, #FEE7D7 100%), linear-gradient(to right, #FFEBC2, #FECEAC);
  }

  &__label {
    flex: 1;
    height: @buyBoxHeight;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-right: 4px;
    margin-left: 6px;
  }

  &__label-container {
    display: flex;
    height: @buyBoxHeight;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    >span {
      max-height: @buyBoxHeight;
      color: @sui_color_club_rosegold_dark1;
      font-size: 14px;
      line-height: 1;
      font-style: normal;
      font-weight: 400;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }

  &__num{
    margin: 0 2px;
    color: #FA6338;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: @buyBoxHeight;
    img {
      height: 100%;
    }
  }
}


// 处理 count -> price 过度效果
.to-fade{
  transition: opacity 200ms;

  &__out-start {
    opacity: 1;
  }
  &__out-end {
    opacity: 0;
  }

  &__in-start {
    opacity: 0;
  }
  &__in-end {
    opacity: 1;
  }
}

</style>
